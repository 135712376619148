<template>
  <div class="rows" style="padding: 0">
    <div class="cols videowall-content">
      <div class="videowall-settings flex-cell--static">
        <el-collapse v-model="activeName">
          <el-collapse-item :title="$tf(['common.layout'])" name="1">
            <div class="layout-settings-content">
              <div class="layout-buttons-wrapper">
                <div class="cols--center">
                  <div
                    v-for="button in buttons.slice(0, 4)"
                    class="button-wrapper"
                    :class="{ 'button-wrapper--active': isLayoutActive(button) }"
                    @click="switchLayout(button)"
                  >
                    <i class="layout-button" :class="'button-q' + button.layout"></i>
                  </div>
                </div>
                <div class="cols--center">
                  <div
                    v-for="button in buttons.slice(4, 8)"
                    class="button-wrapper"
                    :class="{ 'button-wrapper--active': isLayoutActive(button) }"
                    @click="switchLayout(button)"
                  >
                    <i class="layout-button" :class="'button-qe-' + button.layout"></i>
                  </div>
                </div>
              </div>
            </div>
          </el-collapse-item>
          <el-collapse-item :title="$tf(['common.cameras'])" name="2" class="video-wall-cameras">
            <el-tree
              :data="treeData"
              :props="defaultProps"
              @node-drag-start="handleDragStart"
              draggable
              default-expand-all
              :allow-drag="allowDrag"
              :allow-drop="allowDrop"
            >
            </el-tree>
          </el-collapse-item>
          <el-collapse-item :title="$tf(['common.filters'])" name="3" v-if="state.episodes">
            <el-form class="video-wall-filters" label-position="top">
              <el-form-item :label="$tfo('common.dossier_lists')">
                <el-select
                  name="matched-lists"
                  v-model="filter.matched_lists"
                  multiple
                  @change="clearOnChange('matched_lists')"
                  :placeholder="$tf('common.not_selected')"
                  :multiple-limit="10"
                >
                  <el-option
                    v-for="listItem in $store.state.dossier_lists.items"
                    :key="'dossierlist-' + listItem.id"
                    :value="listItem.id"
                    :label="$filters.shortString(listItem.name)"
                  >
                    <dossier-list-option :item="listItem"></dossier-list-option>
                  </el-option>
                </el-select>
              </el-form-item>

              <el-form-item :label="$tf('common.matches,, 1')">
                <el-select name="no-match" v-model="filter.no_match" @change="clearOnChange('no_match')" placeholder="">
                  <el-option value="" :label="$tf('common.all')"></el-option>
                  <el-option value="True" :label="$tfo(['common.only_without_matches'])"></el-option>
                  <el-option value="False" :label="$tfo(['common.only_with_matches'])"></el-option>
                </el-select>
              </el-form-item>

              <el-form-item :label="$tf('common.acknowledged')">
                <el-select name="acknowledged" v-model="filter.acknowledged" @change="clearOnChange('acknowledged')" placeholder="">
                  <el-option value="" :label="$tf('common.all')"></el-option>
                  <el-option value="False" :label="$tfo('common.only_unacknowledged')"></el-option>
                  <el-option value="True" :label="$tfo('common.only_acknowledged')"></el-option>
                </el-select>
              </el-form-item>

              <el-form-item>
                <el-button name="clear-btn" :disabled="!hasFilters" @click="clearFilters">{{ $tf('reset | filters') }} </el-button>
              </el-form-item>
            </el-form>
          </el-collapse-item>
        </el-collapse>
      </div>
      <div class="rows stretched">
        <div class="content-header cols videowall-header">
          <h2>
            {{ $tf('video_wall') }}
          </h2>
          <div class="fullscreen-button" @click="toggleFullscreen">
            <span>{{ $tf(isFullscreen ? 'exit_fullscreen' : 'enter_fullscreen') }}</span>
            <span class="icon-fullscreen"></span>
          </div>
        </div>
        <div class="cols videowall-wrapper">
          <!-- Single -->
          <div class="videowall-block cols" v-if="state.layout === 1">
            <videowall-item id="cam1" class="flex-cell--1"></videowall-item>
          </div>
          <!-- 2 screens -->
          <div class="videowall-block" v-if="state.layout === 2">
            <div class="videowall-rows-wrapper">
              <videowall-item id="cam1"></videowall-item>
              <videowall-item id="cam2"></videowall-item>
            </div>
          </div>
          <!-- 3 screens -->
          <div class="videowall-block" v-if="state.layout === 3">
            <div class="videowall-rows-wrapper">
              <videowall-item id="cam1"></videowall-item>
              <videowall-item id="cam2"></videowall-item>
              <videowall-item id="cam3"></videowall-item>
            </div>
          </div>
          <!-- 4 screens -->
          <div class="videowall-block" v-if="state.layout === 4">
            <div class="videowall-medium-wrapper">
              <videowall-item id="cam1"></videowall-item>
              <videowall-item id="cam2"></videowall-item>
              <videowall-item id="cam3"></videowall-item>
              <videowall-item id="cam4"></videowall-item>
            </div>
          </div>
          <!-- 6 screens -->
          <div class="videowall-block cols flex-multi" v-if="state.layout === 6">
            <videowall-item id="cam1" class="flex-cell--2-3"></videowall-item>
            <div class="videowall-small-wrapper flex-cell--1-3">
              <videowall-item class="flex-cell--1" id="cam2"></videowall-item>
              <videowall-item class="flex-cell--1" id="cam3"></videowall-item>
            </div>
            <videowall-item class="flex-cell--1-3" id="cam4"></videowall-item>
            <videowall-item class="flex-cell--1-3" id="cam5"></videowall-item>
            <videowall-item class="flex-cell--1-3" id="cam6"></videowall-item>
          </div>
          <!-- 9 screens -->
          <div class="videowall-block cols flex-multi" v-if="state.layout === 9">
            <videowall-item class="flex-cell--1-3" id="cam1"></videowall-item>
            <videowall-item class="flex-cell--1-3" id="cam2"></videowall-item>
            <videowall-item class="flex-cell--1-3" id="cam3"></videowall-item>
            <videowall-item class="flex-cell--1-3" id="cam4"></videowall-item>
            <videowall-item class="flex-cell--1-3" id="cam5"></videowall-item>
            <videowall-item class="flex-cell--1-3" id="cam6"></videowall-item>
            <videowall-item class="flex-cell--1-3" id="cam7"></videowall-item>
            <videowall-item class="flex-cell--1-3" id="cam8"></videowall-item>
            <videowall-item class="flex-cell--1-3" id="cam9"></videowall-item>
          </div>

          <div
            class="episodes flex-cell--1-2 height-100"
            style="overflow: auto"
            v-if="state.episodes && cameras.length"
            v-loading="$store.state.episodes.loading"
          >
            <episode-item :key="'episode-' + item.id" :item="item" v-for="item in $store.state.episodes.videoWallItems"></episode-item>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import VideowallItem from './videowall-item.vue';
import EpisodeItem from '../episodes/item';
import _ from '@/apps/common/lodash';
import DossierListOption from '../dossier-lists/option.vue';

export default {
  components: {
    VideowallItem,
    EpisodeItem,
    DossierListOption
  },
  data() {
    return {
      activeName: ['1', '2', '3'],
      treeData: [],
      isFullscreen: false,
      defaultProps: {
        children: 'children',
        label: 'label'
      },
      buttons: [
        {
          layout: 1,
          episodes: false
        },
        {
          layout: 4,
          episodes: false
        },
        {
          layout: 6,
          episodes: false
        },
        {
          layout: 9,
          episodes: false
        },
        {
          layout: 1,
          episodes: true
        },
        {
          layout: 2,
          episodes: true
        },
        {
          layout: 3,
          episodes: true
        },
        {
          layout: 4,
          episodes: true
        }
      ]
    };
  },
  created() {
    this.loadItems();
  },
  watch: {
    cameras: function () {
      this.applyFilterAndLoadEpisodes();
    },
    'filter.no_match': function () {
      this.loadEpisodes();
    },
    'filter.matched_lists': function () {
      this.loadEpisodes();
    },
    'filter.acknowledged': function () {
      this.loadEpisodes();
    },
    'state.episodes': function (v, p) {
      this.applyFilterAndLoadEpisodes();
      window.dispatchEvent(new Event('resize'));
    },
    '$store.state.faces_events.commandItems': function (v, p) {
      if (!this.state.episodes) return;
      this.applyFilterAndLoadEpisodes();
      this.$notify({ message: this.$tf('events | were | acknowledged,,1'), position: 'bottom-right' });
    }
  },
  mounted() {
    if (localStorage.layout) {
      this.state.layout = parseInt(localStorage.layout);
      this.state.episodes = localStorage.episodes === 'true';
    }

    if (localStorage.videoWallFilter) Object.assign(this.$store.state.episodes.filter.videoWall, JSON.parse(localStorage.videoWallFilter));

    let camera_groups = this.$store.dispatch(this.$store.state.camera_groups.Action.Get),
      cameras = this.$store.dispatch(this.$store.state.cameras.Action.GetAll);
    Promise.all([camera_groups, cameras]).then(() => {
      this.createCamerasTree();
    });
    this.$store.dispatch('getAllDossiers');
    this.$store.state.app.fileHandler = false;
    window && window.addEventListener('fullscreenchange', this.fullScreenChangeHandler);
  },
  computed: {
    state() {
      return this.$store.state.video_wall;
    },
    cameras() {
      return Object.keys(this.state.items)
        .map((v) => this.state.items[v] && this.state.items[v].id)
        .filter((v) => !!v);
    },
    filter() {
      return this.$store.state.episodes.filter.videoWall;
    },
    hasFilters() {
      const difference = _.differenceOf(this.$store.state.episodes.filter.videoWall, this.$store.state.episodes.filter.empty),
        result = Object.keys(difference).filter((v) => v !== 'cameras').length;
      return !!result;
    }
  },
  methods: {
    loadEpisodes() {
      if (!this.cameras.length) return;
      localStorage.videoWallFilter = JSON.stringify(this.filter);
      return this.$store.dispatch(this.$store.state.episodes.Action.Get, { filter: this.$store.state.episodes.filter.videoWall }).then((v) => {
        this.$store.state.episodes.videoWallItems = v.results;
      });
    },
    isLayoutActive(item) {
      return this.state.layout === item.layout && this.state.episodes === item.episodes;
    },
    applyFilterAndLoadEpisodes() {
      if (!this.state.episodes || _.isEqual(this.cameras, this.$store.state.episodes.filter.videoWall.cameras)) return;

      const episodesState = this.$store.state.episodes,
        cameras = this.cameras;

      Object.assign(episodesState.filter.videoWall, { cameras });

      if (cameras.length) {
        this.loadEpisodes();
      } else {
        episodesState.videoWallItems = [];
      }
    },
    loadItems() {
      if (localStorage.getItem('items')) {
        try {
          this.$store.state.video_wall.items = JSON.parse(localStorage.getItem('items'));
        } catch (e) {
          console.warn(e);
        }
      }
    },
    fullScreenChangeHandler(ev) {
      if (document.fullscreenElement === null) {
        this.isFullscreen = false;
      }
    },
    createCamerasTree() {
      let newCameraGroups = this.$store.getters.cameraGroupsWithoutSpecial.map((item) => {
        item.label = item.name;
        return (({ id, name, label }) => ({ id, name, label }))(item);
      });
      newCameraGroups.map((item) => {
        item.children = [];
        this.$store.state.cameras.items.map((camera) => {
          if (camera.group === item.id) {
            camera.label = camera.name;
            item.children.push(camera);
          }
        });
      });
      this.treeData = newCameraGroups;
    },
    handleDragStart(node, ev) {
      this.state.draggingItem = node.data;
      ev.srcElement.firstChild.style.cursor = 'move';
    },
    allowDrag(draggingNode) {
      return draggingNode.level > 1;
    },
    allowDrop(draggingNode, dropNode, type) {
      return false;
    },
    deleteUnusedCameras() {
      const currentCamLength = this.state.layout;
      let camItemsLength = 9;
      while (camItemsLength > currentCamLength) {
        const cameraId = `cam${camItemsLength}`;
        if (this.$store.state.video_wall.items[cameraId]) {
          delete this.$store.state.video_wall.items[cameraId];
          this.cameras.pop();
        }
        camItemsLength -= 1;
      }
      localStorage.setItem('items', JSON.stringify(this.$store.state.video_wall.items));
    },
    switchLayout(item) {
      this.state.layout = item.layout;
      this.state.episodes = item.episodes;
      localStorage.layout = this.state.layout;
      localStorage.episodes = this.state.episodes;
      window.dispatchEvent(new Event('resize'));
      this.deleteUnusedCameras();
    },
    toggleFullscreen(e) {
      const el = document.documentElement;
      if (!(document.fullscreenEnabled || document.webkitFullscreenEnabled)) return;
      if (!this.isFullscreen) {
        if (el.requestFullscreen) {
          el.requestFullscreen();
        } else if (el.webkitRequestFullscreen) {
          el.webkitRequestFullscreen();
        }
        this.isFullscreen = true;
      } else {
        if (document.exitFullscreen) {
          document.exitFullscreen();
        } else if (document.webkitExitFullscreen) {
          document.webkitExitFullscreen();
        }
        this.isFullscreen = false;
      }
    },
    clearOnChange(v) {},
    clearFilters() {
      this.$store.state.episodes.filter.videoWall = Object.assign(_.cloneDeep(this.$store.state.episodes.filter.empty), {
        cameras: this.$store.state.episodes.filter.videoWall.cameras
      });
    }
  }
};
</script>
